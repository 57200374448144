<template>
     <div class="body-bg">
        <!-- <iframe v-show="trtcShow" src="https://maplays.net/guarantee/trtc/index.html?roomId=9&userId=lllzjd" frameborder="0" style="width: 100%;height:100%;overflow-y : hidden;"></iframe> -->
        <!-- <iframe v-show="trtcShow" :src="trtcUrl" frameborder="0" style="width: 100%;height:100%;overflow-y : hidden;"  allow = "microphone;camera;midi;encrypted-media;"></iframe> -->
        <van-popup v-model="popupShow" round :close-on-click-overlay="false">
            <div class="hint-popup" >
                <div class="hint-title">提示</div>
                <div class="hint-text">联系客服请先登录</div>
                <div class="hint-btn">
                    <span @click="cancel">取消</span>
                    <span @click="login">去登陆</span>
                </div>
            </div>
        </van-popup>
        <!-- <van-button type="primary" :url="trtcUrl">主要按钮</van-button> -->
    </div>
</template>
<script>
import router from "@/router";
import TEMPURL from "../../utils/tempurl";

export default {
  data() {
    return {
      trtcShow:false,
      popupShow:true,
      trtcUrl:''
    };
  },
  methods: {
    cancel(){
        this.popupShow=false;
        this.$router.push('/')
    },
    login() {
          if (window.localStorage.getItem('token') == null){
             window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
          }else {
              router.push('/my?hasLogin')
          }
    }
  },

  created() {
    
     if(window.localStorage.getItem('token')!=null) {
        // this.trtcUrl='https://maplays.net/guarantee/trtc/index.html?roomId=9&userId='+window.localStorage.getItem('phone');
        //this.trtcUrl='https://maplays.net/guarantee/trtc/index.html?roomId=9&userId=18980788458';
          window.location.href='https://maplays.net/guarantee/trtc/index.html?roomId=9&userId='+window.localStorage.getItem('phone');
        //this.trtcShow=true;
         this.popupShow=false;
     } else {
        //this.trtcShow=false;
          this.popupShow=true;
     }
  }
};
</script>
<style lang="less" scoped>
    .hint-popup {
        .hint-title {
            font-size: 36px;
            color: #333;
            text-align: center;
            padding: 40px 0 0;
        }
        .hint-text {
            font-size: 32px;
            color: #666;
            text-align: center;
            padding: 40px 0;
        }
        .hint-btn {
            display: -webkit-flex;
	        display: flex;
            border-top: 1px solid #ddd;
            line-height: 80px;
            span {
                -webkit-flex: 1;
                flex: 1;
                font-size: 28px;
                color: #666;
                text-align: center;
                 &:first-child {
                     border-right:1px solid #ddd;
                 }
                 &:last-child {
                    color:rgb(254, 138, 79);
                 }

            }

        }
    }
   .van-popup--center {
        top: 40%;
        left: 20%;
        width: 60%;
        -webkit-transform: translate3d(0,0,0);
        transform: translate3d(0,0,0);
    }
</style>
